import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import axiosClient from "../../utils/axiosClient";
import { useAppDispatch } from "@/lib/store/hooks";
import { setShippingAddress } from "../../lib/store/Slices/CartSlice";
import CreateShipping from "../Shippings/CreateShipping/CreateShipping";


function ShippingDDListOffer(props) {
  const [selectedShippingOption, setSelectedShippingOption] = useState("");
  const [shippingInfos, setShippingInfos] = useState([]);
  const [isFromPB, setIsFromPB] = useState(false);
  const [showShipping, setShowShipping] = useState(null);
  const dispatch = useAppDispatch();
  const SetTureFromPb = () => setIsFromPB(true);
  const SetShippingValFromPb = (selectVal) => {
    if (selectVal.value !== undefined) {
      let shp = shippingInfos.filter((v) => v.id == selectVal);
      dispatch(setShippingAddress(shp));
      setSelectedShippingOption(selectVal.value);
      props.onAddressSelect(shippingInfos, selectVal.value);
    }
    setIsFromPB(false);
  };


  const onChangeShippingOtipon = (val) => {

    if (val === undefined || val === "") {
      return true;
    }
    if (val === "new") {
      handleShowShipping();
    } else {
      let shp = shippingInfos.filter((v) => v.id === val);
      dispatch(setShippingAddress(shp));

      setSelectedShippingOption(val);
      props.onAddressSelect(shippingInfos, val);
    }
  };

  const handleShowShipping = () => {
    SetTureFromPb();
    if (showShipping === null) {
      if (showShipping === null) {
        setShowShipping(true);
      }
    } else {
      setShowShipping(!showShipping);
    }
  };

  useEffect(() => {
    getshippings();
  }, []);

  const refreshDataShipping = () => {
    getshippings();
  };

  function getshippings() {
    const userData = JSON.parse(localStorage.getItem("login"));
    axiosClient
      .get("getShippingsInfoByUserId/" + Number(userData.user.id))
      .then((res) => {
        setShippingInfos(res);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <>
      {/* <label>Select Shipping Address</label> */}
      <Form.Select
        value={selectedShippingOption}
        onChange={(event) => onChangeShippingOtipon(event.target.value)}
      >
        <option>Select Shipping Address</option>
        <option
          value="new"
          style={{
            backgroundColor: "#333",
            color: "#f5f5f5",
            padding: "8px",
            border: "none",
            borderRadius: "4px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            cursor: "pointer",
          }}
        >
          {" "}
          Add New Shipping
        </option>
        {shippingInfos &&
          shippingInfos.map((shippingInfo) => (
            <option value={shippingInfo.id}>{shippingInfo.nameAlias}</option>
          ))}
      </Form.Select>

      <CreateShipping
        show={showShipping}
        refreshData={refreshDataShipping}
        SetValFromPb={SetShippingValFromPb}
        isFromPB={isFromPB}
      />
    </>
  );
}

export default ShippingDDListOffer;
