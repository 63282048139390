// utils/dateUtils.js
export const isNewArrival = (modifiedDate) => {
    // Get the current date
    const currentDate = new Date();
  
    // Convert the modified date string to a Date object
    const modifiedDateObj = new Date(modifiedDate);
  
    // Calculate the difference in time (in milliseconds)
    const timeDifference = currentDate - modifiedDateObj;
  
    // Convert the difference to days (1 day = 24 hours * 60 minutes * 60 seconds * 1000 milliseconds)
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
  
    // Return true if the difference is within 16 days, otherwise false
    return daysDifference <= 45;
  };
  