import React, { useState, useEffect } from "react";
import { Modal, Button, FormGroup, Alert, Form } from "react-bootstrap";
// import { handleAddCart, getTotals } from "../Slices/CartSlice";
import { toast } from "react-toastify";
import { useAppDispatch } from "@/lib/store/hooks";
import { useRouter } from "next/router";
import axiosClient from "../../utils/axiosClient";
import "./MakeOffer.css";
import * as appConstants from "../../utils/constants";
import { add } from "lodash";
import { setShippingAddress } from "../../lib/store/Slices/CartSlice";
import ShippingDDListOffer from "../Shippings/ShippingDDListOffer";
import Swal from "sweetalert2";

function MakeOffer(props) {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [show, setShow] = useState(false);
  const [offerPrice, setOfferPrice] = useState("");
  const [offerAccepted, setOfferAccepted] = useState(false);
  const [offerRejected, setOfferRejected] = useState(false);
  const [chances, setChances] = useState(3);
  var userData = JSON.parse(localStorage.getItem("login"));
  const [name, setName] = useState("");
  const [email, setMail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [personalPurchase, setPersonalPurchase] = useState(false); // Step 1
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [shippingInfos, setShippingInfos] = useState([]);
  const [selectedShippingOption, setSelectedShippingOption] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [UserSelectedAddress, setUserSelectedAddress] = useState([]);
  const [UserSelectedValue, setUserSelectedValue] = useState(null);
  const [city, setCity] = useState("");
  const [state, setState] = useState("");

  const [modalTitle, setModelTitle] = useState(
    "Make an offer and let's make a deal that works for you!"
  );
  let webLink = `https://kingsurplus.com${props.item.productSlug}`;
  let viewItemKS = `<div style="font-family: Segoe UI; max-width: 70%; margin: 0 0; padding: 0px;">
    <a href="${webLink}" style="color: blue; text-decoration: underline;font-family: Segoe UI; ">
    Click to View Item on Website 
     </a> </div>`;

  let QBItemLink = `https://kingsurplus-9736.quickbase.com/db/brx4utrtg?a=dr&rid=${props.item.qbRecordId}`;
  let viewItemQb = `<div style="font-family: Segoe UI; max-width: 70%; margin: 0 0; padding: 0px;">
     <a href="${QBItemLink}" style="color: blue; text-decoration: underline;font-family: Segoe UI; ">
      Click To view Item detail Quickbase
      </a> </div>`;
  const [qty, setQty] = useState(1);
  const [customerNumber, setCustomerNumber] = useState("");

  const logo = "https://kingsurplus.com/assets/images/logo.webp";
  const callusimg = "https://kingsurplus.com/assets/images/callusbtn.png";
  const emailusimg = "https://kingsurplus.com/assets/images/emailusbtn.png";

  let maxChanges = 3;
  const handleClose = () => {
    setOfferPrice("");
    setShow(false);
    setError("");
    props.close();
  };

  const extractImageUrl = (htmlString) => {
    const imgTagMatch = htmlString.match(/<img[^>]+src="([^">]+)"/);
    return imgTagMatch ? imgTagMatch[1] : null;
  };

  const handleAddressSelect = (selectedAddress, val) => {
    let foundAddress = selectedAddress.find((address) => address.id == val);

    let newAddress =
      foundAddress.address1 +
      +foundAddress.address2 +
      " " +
      foundAddress.city +
      " " +
      foundAddress.state +
      " " +
      foundAddress.zip_PostalCode +
      " " +
      foundAddress.country;

    setCity(foundAddress.city);
    setState(foundAddress.state);
    setAddress(newAddress);
  };

  const onChangeShippingOtipon = (val) => {
    // ...
    let shp = shippingInfos.filter((v) => v.id === val);
    dispatch(setShippingAddress(shp));
    setSelectedShippingOption(val);

    // Call the onAddressSelect function with the selected address
    props.onAddressSelect(shp);
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    if (!emailPattern.test(email)) {
      setEmailError("Invalid email address.");
    } else {
      setEmailError("");
    }

    setMail(email);
  };

  const handlePhoneChange = (e) => {
    let phone = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    const usaPhonePattern = /^(\+1|1)?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

    if (!usaPhonePattern.test(phone)) {
      setPhoneError("Invalid phone number. Please enter a valid phone number.");
    } else {
      setPhoneError("");
      // phone = phone.replace(/(\d{3})(\d{3})(\d{4})/, "+1 ($1) $2-$3");
    }

    setPhone(phone);
  };

  useEffect(() => {
    if (userData && userData.user && userData.user.customerQBId) {
      setCustomerNumber(userData.user.customerQBId);
    } else {
      setCustomerNumber("-");
    }

    if (userData && userData.user) {
      axiosClient
        .get("getShippingsInfoByUserId/" + Number(userData.user.id))
        .then((res) => {
          setShippingInfos(res);
        })
        .catch((error) => {
          console.error(error);
        });
    }

    if (props.show !== null) {
      setShow(true);
      setOfferPrice("");
      checkAttempts();
    }
  }, []);

  useEffect(() => {
    if (chances === 0 && !offerAccepted && !offerRejected) {
      toast.warning(
        "Max Offer Attempts has been reached for this Item. Need Help? Dial 830-995-5000 for further assistance and use the reference number. Thank you!."
      );
    }
  }, [chances]);

  const checkAttempts = async () => {
    try {
      setLoading(true);
      const response = await axiosClient.get(`GetAttempts${props.item.id}`);
      setChances(maxChanges - response);
      setLoading(false);
    } catch (error) {
      console.error("Error checking attempts:", error);
      setLoading(false);
    }
  };

  const handleSubmitOffer = async (e) => {
    let offerSubject = "";
    setError("");
    setLoading(true);

    if (
      !userData &&
      (!name || !email || !phone || !offerPrice || !city || !state)
    ) {
      setError("Please fill in all fields before submitting.");
      setLoading(false);
      return;
    }

    if (offerPrice === "") {
      setError(
        "Please indicate the amount you are willing to offer for the product or service."
      );
      return false;
    }
    setModelTitle("Thank you for making an offer!");

    setOfferAccepted(true);
    setOfferRejected(true);

    // if (Number(offerPrice) > Number(props.item.autoAccept)) {
    //   offerSubject = "The offer is accepted ";
    //   toast.success("Sweet Success! Your offer has been accepted!");
    //   setOfferAccepted(true);
    //   setOfferRejected(false);
    //   await checkAttempts();
    // }

    // if (
    //   Number(props.item.autoReject) === 0 &&
    //   Number(offerPrice) <= Number(props.item.autoAccept)
    // ) {
    //   offerSubject = "Client Offer Value " + " " + "$" + offerPrice;
    //   toast.warning(
    //     "Offer in the Loop! A team member will reach out within 24 hours."
    //   );
    //   setOfferAccepted(false);
    //   setOfferRejected(true);
    // }
    // if (
    //   Number(props.item.autoReject) > 0 &&
    //   Number(offerPrice) <= Number(props.item.autoAccept)
    // ) {
    //   offerSubject = "New offer action required";
    //   if (chances != 1) {
    //     toast.warning("Offer Rejected. Let's Try Again.");
    //   }

    //   setChances(chances - 1);
    // }

    // toast.success("Offer Submission Successful!");
    setIsSubmitted(true);
    if (!userData) {
      offerSubject = "New Offer from Unregistered User (Internal Only)";
    } else {
      offerSubject = "New Customer Offer from Registered User (Internal Only)";
    }

    sendOfferEmail(offerSubject);
    sendOfferMailToCustomer();

    setLoading(false);
  };

  function sendOfferEmail(offerSubject) {
    const itemMaintenanceCost = props.item.itemMaintenanceCost || 0; // You can set any default value you want
    const perItemCost = props.item.perItemCost || 0; // You can set any default value you want
    // let perItemCostResult = Number(itemMaintenanceCost) - Number(perItemCost);
    // perItemCostResult = perItemCostResult * qty;
    // let profit = Number(offerPrice) - perItemCostResult;

    // New Formula for calculating profit
    // (Offer Price - Per Item Cost) * Qty
    let profit = (Number(offerPrice) - Number(perItemCost)) * qty;

    let formData = {
      CustomerNumber: "",
      name: "",
      email: "",
      phone: "",
      address: "",
      city: "",
      state: "",
      personalPurchase: personalPurchase ? "Yes" : "No",
      customerOfferedPrice: "$" + offerPrice.toString(),
      SelectedQuantity: qty,
      itemDescription: viewItemKS,
      itemDescriptionQuickbase: viewItemQb,
      itemReferenceNumber: props.item.assetNumber,
      itemAutoAcceptPrice: props.item.autoAccept,
      itemAutoRejectPrice: props.item.autoReject,
      itemModel: props.item.model,
      itemIndustry: props.item.industryName,
      itemCategory: props.item.categoryName,
      itemReferenceNumber: props.item.assetNumber,
      Manufacturer: props.item.menufacturer,
      grossProfit: appConstants.formattedValue(profit),
    };

    if (!userData) {
      formData.name = name;
      formData.email = email;
      formData.phone = phone;
      formData.address = address;
      formData.personalPurchase = personalPurchase ? "Yes" : "No";
    } else {
      formData.CustomerNumber = userData.user.customerQBId;
      formData.name = userData.user.fullName;
      formData.email = userData.user.email;
      formData.phone = userData.user.phoneNumber;
      formData.address = address;

      formData.personalPurchase = personalPurchase ? "Yes" : "No";
    }

    var bodyHtml = appConstants.getFormDataHtml(formData);

    const coverImageURL = extractImageUrl(props.item.coverImageURL);

    // Create the payload object
    const payload = {
      subject: offerSubject,
      item: {
        coverImageURL: coverImageURL,
        title: props.item.title,
        industryName: props.item.industryName,
        categoryName: props.item.categoryName,
        assetNumber: props.item.assetNumber,
        model: props.item.model,
      },
      qty: qty,
      customerNumber: customerNumber,
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      address: formData.address,
      personalPurchase: formData.personalPurchase,
      city: city,
      state: state,
      customerOfferedPrice: formData.customerOfferedPrice,
      webLink: webLink,
      QBItemLink: QBItemLink,
    };

    fetch("/api/NewOfferSubInternalEmail", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to submit form");
        }
        // toast.success("Offer submitted successfully!");
      })
      .catch((error) => {
        toast.error("Form not submitted!");
        console.error(error);
      });
  }

  function sendOfferMailToCustomer() {
    let formData = {
      CustomerNumber: "",
      name: "",
      email: "",
      phone: "",
      address: "",
      personalPurchase: personalPurchase ? "Yes" : "No",
      yourOfferedPrice: "$" + offerPrice.toString(),
      SelectedQuantity: qty,
      itemDescription: viewItemKS,
      // itemDescriptionQuickbase: viewItemQb,
      itemReferenceNumber: props.item.assetNumber,
      itemModel: props.item.model,
      itemIndustry: props.item.industryName,
      itemCategory: props.item.categoryName,
      Manufacturer: props.item.menufacturer,
    };

    if (!userData) {
      formData.name = name;
      formData.email = email;
      formData.phone = phone;
      formData.address = address;
      formData.personalPurchase = personalPurchase ? "Yes" : "No";
    } else {
      formData.CustomerNumber = userData.user.customerQBId;
      formData.name = userData.user.fullName;
      formData.email = userData.user.email;
      formData.phone = userData.user.phoneNumber;
      formData.address = address;
      formData.personalPurchase = personalPurchase ? "Yes" : "No";
    }

    function createMailtoLink(item, offerPrice) {
      const subject = encodeURIComponent(`Enquiry About - ${props.item.title}`);
      const body = encodeURIComponent(`
      Item Name: ${props.item.title}
      Item Category: ${props.item.categoryName}
      Manufacturer: ${props.item.menufacturer}
      Asset Number: ${item.assetNumber}
      Offer Price: ${offerPrice}
      Phone Number: ${formData.phone}
      Email ID: ${formData.email}
      User Name: ${formData.name}
      Customer Number: ${customerNumber}
      `);

      return `mailto:sales@kingsurplus.com?subject=${subject}&body=${body}`;
    }

    const mailtoLink = createMailtoLink(props.item, offerPrice);

    const coverImageURL = extractImageUrl(props.item.coverImageURL);

    const customerPayload = {
      item: {
        coverImageURL: coverImageURL,
        title: props.item.title,
        industryName: props.item.industryName,
        categoryName: props.item.categoryName,
        assetNumber: props.item.assetNumber,
        model: props.item.model,
        manufacturer: props.item.menufacturer,
      },
      qty: qty,
      customerNumber: customerNumber,
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      yourOfferedPrice: formData.yourOfferedPrice,
      city: city,
      state: state,
      customerOfferedPrice: formData.customerOfferedPrice,
      webLink: webLink,
      customerEmail: formData.email,
      mailtoLink: mailtoLink,
    };

    var bodyHtml = appConstants.getFormDataHtml(formData);

    fetch("/api/NewOfferSubToCustomerEmail", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(customerPayload),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to submit form");
        }
        Swal.fire({
          title: "Offer Submitted Successfully",
          icon: "success",
          confirmButtonText: "Close",
        });
      })
      .catch((error) => {
        toast.error("Form not submitted!");
        console.error(error);
      });
  }

  // function addCart(selectedItem) {
  //   setLoading(true);
  //   let UpdateItem = selectedItem;
  //   UpdateItem.salePrice = offerPrice;
  //   UpdateItem.IsOffer = true;
  //   var loginData = JSON.parse(localStorage.getItem("login"));
  //   if (loginData === null) {
  //     navigate("/login");
  //   } else {
  //     dispatch(handleAddCart(UpdateItem));
  //     dispatch(getTotals());
  //     setLoading(false);
  //     toast.success("Asset Added To Cart.");
  //   }
  // }

  // function buyNow(selectedItem) {
  //   setLoading(true);
  //   let UpdateItem = selectedItem;
  //   UpdateItem.salePrice = offerPrice;
  //   UpdateItem.IsOffer = true;
  //   var loginData = JSON.parse(localStorage.getItem("login"));
  //   if (loginData === null) {
  //     navigate("/login");
  //   } else {
  //     dispatch(handleAddCart(UpdateItem));
  //     dispatch(getTotals());
  //     navigate("/ShoppingCart");
  //     setLoading(false);
  //   }
  // }

  const handleIncrement = () => {
    if (props.item.quantity > qty) {
      setQty(qty + 1);
    }
  };
  const handleDecrement = () => {
    if (qty > 1) {
      setQty(qty - 1);
    }
  };

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.show}
      onHide={props.close}
      animation={false}
    >
      <Modal.Header closeButton>
        <div className="d-flex flex-column">
          <div className="d-flex flex-row justify-content-between">
            <Modal.Title>{modalTitle}</Modal.Title>
            {/* {userData && offerAccepted && (
              <div
                className="modal-title h6 mt-2"
                style={{ position: "absolute", right: "4rem" }}
              >
                {" "}
                Customer# {userData.user.customerQBId}{" "}
              </div>
            )} */}
          </div>

          {/* <div>
            <small style={{ fontSize: "12px" }}>
              Remember: You have only 3 offers per item! Make them count!
            </small>
          </div> */}
        </div>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="make-offer-container">
            {error && (
              <Alert className="mt-2 RedAlert" variant="danger">
                {error}
              </Alert>
            )}
            {emailError && (
              <Alert className="mt-2 RedAlert" variant="danger">
                {emailError}
              </Alert>
            )}
            {phoneError && (
              <Alert className="mt-2 RedAlert" variant="danger">
                {phoneError}
              </Alert>
            )}
            {!offerAccepted && !offerRejected && (
              <div hidden={chances < 1}>
                <div className="">
                  {!userData && (
                    <div className="d-flex flex-row mb-2 offer-box-u">
                      <div className="input-group me-1">
                        <FormGroup className="input-groupWrap">
                          <span className="input-group-text">Name</span>
                          <input
                            className="form-control"
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Enter Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            autoComplete="off"
                            required
                          />
                        </FormGroup>
                      </div>

                      <div className="input-group me-1">
                        <FormGroup className="input-groupWrap">
                          <span className="input-group-text">Email</span>

                          <input
                            className="form-control"
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Enter Your Email"
                            value={email}
                            // onChange={(e) => setMail(e.target.value)}
                            onChange={handleEmailChange}
                            autoComplete="off"
                            required
                          />
                        </FormGroup>
                      </div>

                      <div className="input-group me-1">
                        <FormGroup className="input-groupWrap">
                          <span className="input-group-text">Phone</span>
                          <input
                            className="form-control"
                            type="tel"
                            name="phone"
                            id="phone"
                            placeholder="Enter Phone Number"
                            value={phone}
                            onChange={handlePhoneChange}
                            autoComplete="off"
                            pattern="^[0-9]{3,45}$"
                            required
                          />
                        </FormGroup>
                      </div>
                    </div>
                  )}
                  {/* {!userData && ( */}
                  <div className="d-flex flex-row mb-2">
                    <div className="input-group me-1">
                      {userData ? (
                        <ShippingDDListOffer
                          onAddressSelect={handleAddressSelect}
                        />
                      ) : (
                        <div className="d-flex">
                          <div className="input-group me-1">
                            <FormGroup className="input-groupWrap me-2 ">
                              <span className="input-group-text">Address</span>
                              <input
                                className="form-control address-input-2 "
                                type="text"
                                name="address"
                                id="address"
                                placeholder="Address"
                                value={address}
                                autoComplete="off"
                                onChange={(e) => {
                                  setAddress(e.target.value);
                                }}
                              />
                            </FormGroup>
                          </div>
                          <div className="input-group me-1">
                            <FormGroup className="input-groupWrap me-2 ">
                              <span className="input-group-text">City</span>

                              <input
                                className="form-control"
                                type="text"
                                name="city"
                                id="city"
                                placeholder="Enter City"
                                value={city}
                                autoComplete="off"
                                onChange={(e) => setCity(e.target.value)}
                              />
                            </FormGroup>
                          </div>
                          <div className="input-group me-1">
                            <FormGroup className="input-groupWrap me-2 ">
                              <span className="input-group-text">State</span>

                              <input
                                className="form-control"
                                type="text"
                                name="state"
                                id="state"
                                placeholder="Enter State"
                                value={state}
                                autoComplete="off"
                                onChange={(e) => setState(e.target.value)}
                              />
                            </FormGroup>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="d-flex flex-row">
                    <div className="">
                      <FormGroup className="input-groupWrap">
                        <span className="input-group-text">$</span>
                        <input
                          className="form-control"
                          type="number"
                          name="offerPrice"
                          id="offerPrice"
                          placeholder="Add Offer"
                          autoComplete="off"
                          // value={offerPrice}
                          onChange={(e) => {
                            if (e.target.value != "") {
                              setOfferPrice(Number(e.target.value));
                            }
                          }}
                        />
                      </FormGroup>
                    </div>
                    <div className="d-flex flex-row text-center ps-3 me-4 mt-2">
                      <span className="pe-2 mt-1"> Quantity</span>{" "}
                      {props.item.quantity < 2 && (
                        <>
                          {" "}
                          <span className="quantity mt-1">{qty} </span>
                        </>
                      )}
                      <div className="qtyNumber">
                        <br />
                        {props.item.quantity > 1 && (
                          <div className="quantity-selector">
                            <a
                              className="btn btn-sm btn-primary"
                              onClick={handleDecrement}
                            >
                              <b>-</b>{" "}
                            </a>
                            <span className="quantity">{qty} </span>
                            <a
                              className="btn btn-sm btn-primary"
                              onClick={handleIncrement}
                            >
                              {" "}
                              <b>+</b>{" "}
                            </a>
                          </div>
                        )}
                      </div>
                    </div>

                    {/* <div>
                      <button
                        // disabled={loading}

                        className="btn btn-blue"
                        type="button"
                        onClick={handleSubmitOffer}
                      >
                        Submit
                      </button>
                    </div> */}
                  </div>

                  <div className="form-check form-switch custom-switch">
                    <label>Personal Purchase</label>
                    <input
                      className="form-check-input custom-input"
                      type="checkbox"
                      onChange={(e) => setPersonalPurchase(e.target.checked)}
                      checked={personalPurchase}
                    />
                  </div>
                </div>
              </div>
            )}

            {offerAccepted && offerRejected && (
              <div className="MakeOfferInfo">
                <h6>
                  {" "}
                  A customer representative will reach out to you within the
                  next hour.{" "}
                </h6>
                <p className="lead">
                  Please note that if your offer was submitted after our
                  operating hours, we will make it a priority to respond first
                  thing in the morning.
                </p>
                <div className="ref-boxes">
                  {userData && (
                    <h5 className="pb-3">
                      Customer Number<span>{userData.user.customerQBId}</span>
                    </h5>
                  )}

                  <h5 className="pb-3 pb-3-custom">
                    Reference Number <span>{props.item.assetNumber}</span>
                  </h5>
                </div>
              </div>

              // <div className="mt-2">
              //   <Alert className="RedAlert" variant="success">
              //     Congratulations, your offer has been accepted!
              //   </Alert>
              //   <Button
              //     onClick={() => addCart(props.item)}
              //     variant="primary"
              //     className="me-2"
              //   >
              //     Add to cart
              //   </Button>
              //   <Button onClick={() => buyNow(props.item)} variant="primary">
              //     Checkout now
              //   </Button>
              // </div>
            )}

            {/* {!offerAccepted && !offerRejected && !loading && chances > 0 && (
                <Alert className="mt-2 RedAlert" variant="danger">
                  You have {chances} offers left.{" "}
                </Alert>
              )} */}

            {/* {chances < 1 && !offerAccepted && !offerRejected && (
                <Alert className="mt-2 RedAlert" variant="danger">
                  You have reached the maximum number of attempts. You can no
                  longer make an offer.so make them good!
                </Alert>
              )} */}

            {/* {offerAccepted == false && offerRejected == true && (
                <div className="MakeOfferInfo">
                  <h6>
                    Your offer has been submitted successfully! We will contact
                    you as soon as possible.
                  </h6>

                  <p>
                    If you want to talk with a customer representative, please
                    call us at <a href="tel:830-995-5000">830-995-5000 </a>{" "}
                  </p>
                  <h5>
                    Reference Number <span>{props.item.assetNumber}</span>
                  </h5>
                </div>
              )} */}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex main-container">
          <div className="notice-container">
            <div className="img-box">
              <img
                className="notice-icon"
                src="/assets/images/information.png"
                alt="notice"
              ></img>
              <p className="notice">
                By submitting this offer (item only, excluding
                packaging/transport fees), you are entering into a legally
                binding agreement. Non-payment or halting communication
                post-acceptance will lead to legal action under Texas law, Venue
                is Val Verde County.
              </p>
            </div>
          </div>
          <div className="d-flex offer-footer">
            {!isSubmitted && (
              <Button
                className="offer-btn"
                variant="primary"
                onClick={handleSubmitOffer}
              >
                Submit
              </Button>
            )}
            <Button
              className="offer-btn"
              variant="secondary"
              onClick={handleClose}
            >
              Close
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default MakeOffer;
